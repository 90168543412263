import React from 'react'
import { Link as RebassLink, Flex } from 'rebass/styled-components';
import theme from './../gatsby-plugin-theme-ui';

import SEO from './../components/templates/seo';
import Section from './../components/atoms/section';
import Heading from './../components/atoms/heading';
import Text from './../components/atoms/text';
import Border from './../components/atoms/border';
import Vector from './../components/atoms/vector';

// MEDIA
import FacebookIcon from './../media/svg/facebook.inline.svg';

export default function NotFound(props) {

    const border_props = (position, width, height, transform, display) => ({
        position, width, height, transform, display
    })

    const border_top_props = border_props(
        { top: 0, left: 0 }, '80%', 3,
        null, { display: ['none', 'none', 'inherit'] }
    )

    const border_left_props = border_props(
        { top: 0, left: 0 }, ['80%', '80%', 3],
        [3, 3, '80%'], { transform: [null, null, 'rotate(180deg)'] }, null
    )

    const border_right_props = border_props(
        { bottom: 0, right: '1px' }, '80%', 3,
        { transform: 'rotate(180deg)' }, { display: ['none', 'none', 'inherit'] }
    )

    const border_bottom_props = border_props(
        { bottom: 0, right: 0 }, ['80%', '80%', 3],
        [3, 3, '80%'], { transform: ['rotate(180deg)', 'rotate(180deg)', 'rotate(0deg)'] }, null
    )

    const heading_info_props = {
        as: 'h2',
        fontWeight: '700',
        fontSize: [18, 20, 22],
        margin: '1rem auto'
    }
    
    const text_info_props = {
        as: 'p',
        fontSize: [14, 16, 18],
        margin: '0.5rem auto',
        textAlign: 'center'
    }

    const footer_vector_props = icon => ({
        width: '100%',
        height: '100%',
        Icon: icon
    })

    const footer_fb_link_props = {
        sx: {
            width: [48, 48, 58], height: [48, 48, 58], margin: '2rem auto'
        },
        href: 'https://www.facebook.com/ardor.kominki/',
        target: '_blank',
        rel: 'noopener'
    }

    return (
        <>
            <SEO title='Napisz do nas' />
            <Section sx={{
                width: '100%',
                height: 'auto',
                minHeight: 200,
                flexFlow: 'column nowrap',
                justifyContent: 'space-between'
            }}>
                <Heading as='h1' background_text='Kontakt' padding='0 1.25rem'
                animation={{
                    'data-sal': 'slide-up',
                    'data-sal-duration': '600',
                    'data-sal-delay': '0',
                    'data-sal-easing': 'cubic-bezier(0.165, 0.84, 0.44, 1)'
                }}>Skontaktuj się</Heading>
                <Text margin='2.5rem 0 0 0' padding='0 1.25rem' textAlign='center'
                animation={{
                    'data-sal': 'slide-up',
                    'data-sal-duration': '600',
                    'data-sal-delay': '200',
                    'data-sal-easing': 'cubic-bezier(0.165, 0.84, 0.44, 1)'
                }}>W celu omówienia szczegółów, skontaktuj się z nami telefonicznie bądź mailowo na adres:
                <RebassLink sx={{
                    textDecoration: 'none',
                    fontWeight: 700,
                    color: theme.colors.black
                }} href='mailto: kominki.ardor@gmail.com'> kominki.ardor@gmail.com</RebassLink></Text>
                <Flex sx={{ 
                    flexDirection: ['column', 'column', 'row'],
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 400
                }}>
                    <Flex flexDirection='column' sx={{
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: ['100%', '100%', 400],
                        minHeight: 250,
                        margin: '2rem'
                    }} 
                    data-sal='slide-left'
                    data-sal-duration='600'
                    data-sal-delay='0'
                    data-sal-easing='cubic-bezier(0.165, 0.84, 0.44, 1)'
                    >
                        <Text {...text_info_props}>Kaja - 502 673 505</Text>
                        <Border {...border_left_props} />
                        <Border {...border_top_props} />
                        <Border {...border_bottom_props} />
                        <Border {...border_right_props} />
                    </Flex>
                    <Flex flexDirection='column' sx={{
                        position: 'relative',
                        minWidth: ['100%', '100%', 400],
                        minHeight: 250,
                        margin: '2rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    data-sal='slide-right'
                    data-sal-duration='600'
                    data-sal-delay='0'
                    data-sal-easing='cubic-bezier(0.165, 0.84, 0.44, 1)'>
                        <Text {...text_info_props} padding='0 2.5rem'>ul. Szosa Szubińska 35 86-005 Białe Błota</Text>
                        <Text {...text_info_props} padding='0 2.5rem'>Godziny otwarcia - Pn-Pt 10-17, Sob 10-13</Text>
                        <Border {...border_left_props} />
                        <Border {...border_top_props} />
                        <Border {...border_bottom_props} />
                        <Border {...border_right_props} />
                    </Flex>
                </Flex>
                <RebassLink {...footer_fb_link_props}><Vector {...footer_vector_props(FacebookIcon)} /></RebassLink>
            </Section>
        </>
    )
}